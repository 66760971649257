import {
    DataGrid,
    GridToolbar,
} from "@mui/x-data-grid";
import "./dataTable.scss";
// import axios from "axios";


const DataTable = (props) => {

    console.log()
    // const handleDeleteUser = async () => {
    //     try {
    //         await axios.delete(`https://balkanflix-server.vercel.app/api/auth/deleteUser/${params.id}`);
    //         console.log(props.id + "sssssssss")
    //         // Ovde dodajte logiku za ažuriranje UI-a nakon brisanja
    //     } catch (error) {
    //         console.error("Error deleting user:", error);
    //     }
    // };

    // const actionColumn = {
    //     field: "action",
    //     headerName: "Action",
    //     width: 100,
    //     renderCell: (params) => {
    //         return (
    //             <div className="action">
    //                 <Link to={`/dashboard/${props.slug}/${params.row.id}`}>
    //                     <img src="/Dashboard/view.svg" alt="" />
    //                 </Link>
    //                 <div className="delete" onClick={() => handleDeleteUser()}>
    //                     <img src="/Dashboard/delete.svg" alt="" />
    //                 </div>
    //             </div>
    //         );
    //     },
    // };

    return (
        <div className="dataTable">
            <DataGrid
                className="dataGrid"
                rows={props.rows}
                columns={[...props.columns]}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 5,
                        },
                    },
                }}
                slots={{ toolbar: GridToolbar }}
                slotProps={{
                    toolbar: {
                        showQuickFilter: true,
                        quickFilterProps: { debounceMs: 500 },
                    },
                }}
                pageSizeOptions={[5]}
                checkboxSelection
                disableRowSelectionOnClick
                disableColumnFilter
                disableDensitySelector
                disableColumnSelector
            />
        </div>
    );
};


export default DataTable;
