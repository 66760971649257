import { useEffect, useState } from "react";
import axios from "axios";
import "./form.scss"

const Forms = () => {
    return (
        <>
            <div className="form-overlay">
                <div className="form-content">
                    <div className="add-notification-form">
                        <h1>Notifikacija</h1>
                        <h5>Unesi informacije za notifikaciju</h5>
                        <div className="add-notification-form__inputs">
                            <div className="add-notification-form__input">
                                <label htmlFor="serial">Serijal</label>
                                <input
                                    placeholder="Unesi serijal"
                                    type="text"
                                />
                            </div>
                            <div className="add-notification-form__input">
                                <label htmlFor="email">Epizoda</label>
                                <input
                                    placeholder="Unesi epizodu"
                                    type="number"
                                />
                            </div>
                        </div>

                        <button className="add-notification-form__btn">
                            Pošalji notifikaciju
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Forms;