import {useEffect, useState} from "react";
import axios from "axios";
import "./ForgotPasswordScreen.scss";
import {Link, useNavigate} from "react-router-dom";
import useDocumentTitle from "../../Title/useDocumentTitle";

const ForgotPasswordScreen = () => {
    const [email, setEmail] = useState("");
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");

    const history = useNavigate();

    useDocumentTitle(`Balkanflix - Forgot Password`);


    useEffect(() => {
        if (localStorage.getItem("authToken")){
            history("/");
        }
    }, [history]);

    const forgotPasswordHandler = async (e) => {
        e.preventDefault();

        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        try {
            const { data } = await axios.post(
                "https://balkanflix-server.vercel.app/api/auth/forgotpassword",
                { email },
                config
            );

            setSuccess(data.data);
        } catch (error) {
            setError(error.response.data.error);
            setEmail("");
            setTimeout(() => {
                setError("");
            }, 5000);
        }
    };

    return (
        <div className={"forgotpassword-screen"}>
            <Link className="menu-return" to="/">{"<- GLAVNA STRANICA"}</Link>
            <div className="forpass-logo">
                <img src="/Balkanflix-removebg.png" alt="" height="70px" width="62px"/>
                <p>Balkanflix</p>
            </div>
            <form onSubmit={forgotPasswordHandler} className="forgotpassword-screen__form">
                <h3 className="forgotpassword-screen__title">Promeni lozinku</h3>
                {error && <span className="error-message">{error}</span>}
                {success && <span className="success-message">{success}</span>}
                <div className="form-group">
                    <p className="forgotpassword-screen__subtext">
                        Molim vas ukucajte email adresu koju ste koristili za registraciju.
                        Mi ćemo Vam poslati link za promenu lozinke na taj mail.
                    </p>
                    <label htmlFor="email">Email:</label>
                    <input
                        type="email"
                        required
                        id="email"
                        placeholder="Email adresa"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </div>
                <button type="submit" className="send-email__btn">
                    Pošalji Email
                </button>
            </form>
        </div>
    );
};

export default ForgotPasswordScreen;
