import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import mongoose from "mongoose";

export const createOrGetUser = async (response) => {

    const decoded = jwtDecode(response.credential);
    const email = decoded.email;
    // const sub = decoded.sub;
    const picture = decoded.picture;
    const name = decoded.name;
    const verified = decoded.email_verified;

    const firstName = name.split(' ')[0];

    const randomNumber = Math.floor(100 + Math.random() * 900);
    const username = `${firstName}${randomNumber}`;

    const user = {
        // _id: sub,
        username: username,
        pfp: picture,
        email: email,
        isVerified: verified
    }
    console.log(decoded);

    const {data} = await axios.post('https://' +
        'balkanflix-server.vercel.app/api/auth/google', user)

    localStorage.setItem('username', data.user.username);
    localStorage.setItem('pfp', data.user.pfp);
    localStorage.setItem('email', data.user.email);
    localStorage.setItem("authToken", data.token);
    localStorage.setItem("isAdmin", data.user.isAdmin);
};
