import {useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import {Link} from 'react-router-dom';
import "./LoginScreen.scss";
import useDocumentTitle from "../../Title/useDocumentTitle";
import {account} from "../../Chat/appwriteConfig/appwriteConfig";
import {ID} from "appwrite";

import {GoogleLogin} from "@react-oauth/google";
import {createOrGetUser} from "../../../utils";

const LoginScreen = () => {

    const history = useNavigate();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");

    useEffect(() => {
        if (localStorage.getItem("authToken")){
            history("/");
        }
    }, [history]);
    useDocumentTitle(`Balkanflix - Login`);

    const waitForDataAndRedirect = () => {
        const checkInterval = setInterval(() => {
            if (localStorage.getItem("authToken")) {
                clearInterval(checkInterval); // Stop the interval once data is found
                history("/"); // Redirect to home page
            }
        }, 100); // Check every 100ms
    };

    const loginHandler = async (e) => {
        e.preventDefault();

        const config = {
            headers: {
                "Content-Type": "application/json"
            }
        }

        try {
            const {data} = await axios.post(
                "https://balkanflix-server.vercel.app/api/auth/login",
                {email, password},
                config
            );
            const { user } = data;
            console.log(user)
            try {
                const result = await account.createEmailSession(email, password); 
                console.log(result);

            }catch (e) {
                if (e.message.includes('user_not_found')) { // Check the exact error message for user not found
                    // Create Appwrite account
                    const newUser = await account.create(ID.unique(), email, password);
                    console.log('Appwrite account created:', newUser);
                    // Optionally store Appwrite user ID in your local database here
                } else {
                    console.error('Error with Appwrite session:', e);
                }
            }
            

            localStorage.setItem('username', data.user.username);
            localStorage.setItem('pfp', data.user.pfp);
            localStorage.setItem('email', data.user.email);
            localStorage.setItem("authToken", data.token);
            localStorage.setItem("isAdmin", data.user.isAdmin);

            history("/");
        }catch (e){
            setError(e.response.data.error);
            setTimeout(()=>{
                setError("");
            }, 5000)
        }
    }

    return (
        <div className="login-screen">
            <Link className="menu-return" to="/">{"<- GLAVNA STRANICA"}</Link>
            <div className="login-logo">
                <img src="/Balkanflix-removebg.png" alt="" height="70px" width="62px"/>
                <p>Balkanflix</p>
            </div>
            <form onSubmit={loginHandler} className="login-screen_form">
                <h3 className="login-screen__title">Log In</h3>
                <p className="login-message">Prijavi se ako imaš nalog</p>
                {error && <span className="error-message">{error}</span>}

                <div className="form-group">
                    <label htmlFor="email">Email:</label>
                    <input className="login--input" type="email" required id="email" placeholder="Unesi email" value={email} onChange={(e) => setEmail(e.target.value)}/>
                </div>

                <div className="form-group">
                    <label htmlFor="password">Lozinka:</label>
                    <input className="login-input" type="password" required id="password" placeholder="Unesi lozinku" value={password} onChange={(e) => setPassword(e.target.value)}/>
                    <span className="login-screen__subtext">
                         <Link className="subtext-link" to="/forgotpassword">Zaboravili ste lozinku?</Link>
                    </span>
                </div>

                <button type="submit" className="login-button">LOGIN</button>

                <span className="login-screen__subtext">
                    Nemaš nalog? <Link className="subtext-link" to="/register">Registruj se</Link>
                </span>

                <GoogleLogin
                    className="google-login-button"
                    onSuccess={(r) => {
                        createOrGetUser(r).then(r => console.log(r));
                        waitForDataAndRedirect();
                    }}
                />
            </form>

        </div>
    )

};

export default LoginScreen;
