import React from 'react';
import './AboutUs.scss';
import useDocumentTitle from '../Title/useDocumentTitle';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { FaHandshakeSimple } from "react-icons/fa6";

const AboutUs = () => {

    useDocumentTitle("Balkanflix - O nama");

    const friends = [
        {
            name: "ZmajevaKugla.rs",
            link: "https://zmajevakugla.rs",
            logo: "https://raw.githubusercontent.com/Strale2006/SlikeStranice/main/partnerzkrs.webp"
        },
        {
            name: "AnimeOverdose",
            link: "https://anime-overdose.com",
            logo: "https://raw.githubusercontent.com/Strale2006/SlikeStranice/main/partneraods.webp"
        },
        {
            name: "Anime Balkan",
            link: "https://animebalkan.org/",
            logo: "https://raw.githubusercontent.com/Strale2006/SlikeStranice/main/partnerab.webp"
        },
        {
            name: "ZedRapid",
            link: "https://www.zedrapid.rs/",
            logo: "https://raw.githubusercontent.com/Strale2006/SlikeStranice/main/ZedRapid.png"
        },
        {
            name: "GledajCrtace",
            link: "https://www.gledajcrtace.xyz/",
            logo: "https://raw.githubusercontent.com/Strale2006/SlikeStranice/main/GC.png"
        }
    ];

    return (
        <section className="about-us">
            <Helmet>
                {/* Meta tagovi */}
            </Helmet>
            <div className="about-us-content">
                <h1>Balkanflix Anime – Gledaj Anime Sa Prevodom Potpuno Besplatno</h1>
                <p>
                    Balkanflix predstavlja revolucionarni online servis posvećen ljubiteljima animea širom Balkana.
                    Naša misija je da spojimo strast prema anime kulturi sa jedinstvenim balkanskim duhom,
                    nudeći širok spektar anime serija dostupnih za besplatno gledanje sa prevodom na srpskom, hrvatskom,
                    bosanskom i ostalim jezicima regiona.
                </p>
                <hr/>
                <h1>Prijatelji sajta <FaHandshakeSimple /></h1>
                <div className="friends-section">
                    {friends.map((friend, index) => (
                        <div className="friend" key={index}>
                            <Link to={friend.link}>
                                <img src={friend.logo} alt={`Logo ${friend.name}`}/>
                                <p className="friend-p">{friend.name}</p>
                            </Link>
                        </div>
                    ))}
                </div>
            </div>
            <div className="anime-image">
                <img src="/Balkanflix-removebg.png" alt="Anime on Balkanflix"/>
            </div>
        </section>
    );
};

export default AboutUs;